@import (reference) '../node_modules/proceduralsystem-clientcomponents/styles/variables.less';
@import "~src/assets/styles/mixins/ellipsis-mixin.less";

html {
  margin: 0;
  height: 100%;
  overflow: hidden;

  body {
    margin: 0;
    height: 100%;
  }

  .radio-list input[type="radio"] {
    &:focus {
      box-shadow: 0 0 1pt 1pt @primary-90  !important;
    }

    &:disabled:hover {
      cursor: default !important;
    }

  }

  .item-value {
    li {
      list-style-position: inside;
    }
  }

  ::selection {
    background: @primary-90;
    /* WebKit/Blink Browsers */
  }

  ::-moz-selection {
    background: @primary-90;
    /* Gecko Browsers */
  }

  .padding-20 {
    padding: 20px 0;
  }

  .padding-30 {
    padding: 30px 0;
  }

  .padding-20-20-0 {
    padding: 20px 20px 20px 0;
  }

  .margin-bottom-80 {
    margin-bottom: 80px !important;
  }
  .margin-bottom-200 {
    margin-bottom: 200px !important;
  }

  .w100 {
    width: 100%;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .flex-item {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  .dialog-item {
    background-color: @disabled-fill;
    border: 1px solid @disabled-fill;
    padding: 10px;
  }

  .dialog-label {
    margin-top: 20px;
  }

  .no-items {
    display: flex;
    justify-content: center;
    padding: 20px 0;
  }

  .invalid-field {
    border: 1px solid @invalid-outline  !important;
    background: @invalid-fill  !important;
  }
  .invalid-field > div.typeahead-container {
    background: @invalid-fill  !important;
  }

  .warning-text {
    color: @invalid-outline;
  }

  .amendment-title {
    padding: 0 10px;
  }
}

.task-manager {
  .mat-mdc-tab-label-container {
    border-bottom: none;
    flex-grow: 0;

    .mat-mdc-tab-labels {
      height: 58px;
    }
  }

  .mdc-tab-indicator {
    display: none;
  }
  
  .mdc-tab {
    height: auto;
    min-width: 160px;
    padding: 20px 30px 15px 30px;
    color: @secondary-90;
    border: none;
    background: repeating-linear-gradient(-40deg, @neutral-background-base, @neutral-background-base 5px, @neutral-background-dark 5px, @neutral-background-dark 7.5px);
    font-size: 15px;
    font-family: 'Montserrat-Regular', san-serif;
    font-weight: bold;
    vertical-align: middle;
    text-align: center;
    outline: none;
    margin-left: 4px;
    opacity: 1 !important;

    &.mdc-tab--active:not(.child-tabs .mdc-tab--active) {
      background: white;
      color: @primary-base;
      border: 1px solid @primary-50;
      border-bottom: none;
    }
  }

  .child-tabs {
    .mdc-tab {
      opacity: 1;
      border: none;
      background: white !important;
      border-bottom: 5px solid white;

      &.mdc-tab--active {
        background: white !important;
        color: @primary-base;
        box-shadow: inset 0px -5px 0px 0px @primary-base !important;
      }
    }

    .mat-mdc-tab-header {
      border-bottom: 1px solid @primary-50;
      margin-bottom: 10px;
    }
  }
}


.dail-business {
  .mat-mdc-tab-label-container {
    border-bottom: none;
    flex-grow: 0;

    .mat-mdc-tab-labels {
      height: 58px;
    }
  }

  .mdc-tab-indicator {
    display: none;
  }
  
  .mdc-tab {
    height: auto;
    min-width: 160px;
    padding: 28px 30px 15px 30px;
    color: @secondary-90;
    border: none;
    background: repeating-linear-gradient(-40deg, @neutral-background-base, @neutral-background-base 5px, @neutral-background-dark 5px, @neutral-background-dark 7.5px);
    font-size: 15px;
    font-family: 'Montserrat-Regular', san-serif;
    font-weight: bold;
    vertical-align: middle;
    text-align: center;
    outline: none;
    margin-left: 4px;
    opacity: 1 !important;

    &.mdc-tab--active:not(.child-tabs .mdc-tab--active) {
      background: white;
      color: @primary-base;
      border: 1px solid @primary-50;
      border-bottom: none;
    }
  }
}

.text-wrapper {
  >textarea {
    min-height: 30px !important;
    max-height: 200px !important;
    box-sizing: content-box;
    padding-right: 15px;
    padding-left: 15px;
  }
  >textarea.journal-notes {
    box-sizing: border-box;
  }
  > textarea[disabled], >input[type=text]:disabled {
    background-color: @primary-40;
  }
}

oir-singleselect {
  >.dropdown {
    >.dropdown-toggle.disabled {
      background-color: @primary-40;
      opacity: 1;
    }
  }
}


.quality-manager, .archive {
  .mat-mdc-tab-label-container {
    border-bottom: none;
    flex-grow: 0;

    .mat-mdc-tab-labels {
      height: 58px;
    }
  }

  .mdc-tab-indicator {
    display: none;
  }
  
  .mdc-tab {
    height: auto;
    min-width: 160px;
    padding: 20px 30px 15px 30px;
    color: @secondary-90;
    border: none;
    background: repeating-linear-gradient(-40deg, @neutral-background-base, @neutral-background-base 5px, @neutral-background-dark 5px, @neutral-background-dark 7.5px);
    font-size: 15px;
    font-family: 'Montserrat-Regular', san-serif;
    font-weight: bold;
    vertical-align: middle;
    text-align: center;
    outline: none;
    margin-left: 4px;
    opacity: 1 !important;

    &.mdc-tab--active:not(.child-tabs .mdc-tab--active) {
      background: white;
      color: @primary-base;
      border: 1px solid @primary-50;
      border-bottom: none;
    }
  }

  .child-tabs {
    .mdc-tab {
      opacity: 1;
      border: none;
      background: white !important;
      border-bottom: 5px solid white;

      &.mdc-tab--active {
        background: white !important;
        color: @primary-base;
        box-shadow: inset 0px -5px 0px 0px @primary-base !important;
      }
    }

    .mat-mdc-tab-header {
      border-bottom: 1px solid @primary-50;
      margin-bottom: 10px;
    }
  }
}

.pendingBorder {
  border-left: 5px solid @invalid-outline;
}

.defaultBorder {
  border-left: 5px solid black;
}

dp-date-picker {
  width: 100%;
}

.dp-picker-input {
  width: 80%;
  border: 0;
  cursor: pointer;
}

.dp-picker-input:focus {
  outline-color: transparent;
}

.page-title {
  padding: 0;
  margin: 30px 45px 35px 10px;
  .ellipses();
}

oir-motion, oir-statement, oir-money-message, oir-other-item, oir-bill {
  .page-title {
    font-family: 'WarnockPro-Regular', serif;
    font-size: 40px;
    color: #444444;
    font-weight: 500;
    line-height: 1.1;

    .page-title-formatted {
      .ellipses();

      * {
        font-family: inherit;
        line-height: inherit !important;
        font-size: inherit !important;
        color: inherit !important;
        max-width: 100% !important;
      }
    }
  }
}

.html-input, .item-value {
  table > tbody > tr > td {
    border: 1px solid @primary-70 !important;
  }
}

.underline {
  border-bottom: 1px solid #444444;
}

@media screen and (min--moz-device-pixel-ratio: 0) {
  .page-title > .page-title-formatted, .item-title > .formatted-title {
    display: -webkit-box !important;
  }
}


body {
  margin: 0px;
}
@-webkit-keyframes top-img {
  from {
    -webkit-transform: rotateY(0deg);
  }
  to {
    -webkit-transform: rotateY(360deg);
  }
}
@keyframes top-img {
  from {
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  to {
    -moz-transform: rotateY(360deg);
    -ms-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}
.spinner-bg {
  position: absolute;
  top: 0;
  opacity: .75;
  background-color: #000;
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 0px;
  margin: 0px;
}
.spinner-wrapper {
  position: absolute;
  top: 50%;
  margin-top: -106.5px;
  left: 50%;
  margin-left: -250px;
}
img {
  position: absolute;
  text-align: center;
  vertical-align: middle;
}
#stage {
  -webkit-perspective: 1500px;
  -moz-perspective: 1500px;
  -ms-perspective: 1500px;
  perspective: 1500px;
}
#top-img {
  z-index: 2;
  -webkit-animation-name: top-img;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: .5s;
  animation-name: top-img;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
#bottom-img {
  z-index: 1;
}
